<template>
  <div
    :class="['ui-icon', `ui-icon__${name}`, {active: active, disabled}, iconSize]"
    @click="$emit('click')"
  />
</template>

<script>
const sizeMap = {
  s: 14,
  m: 16,
  l: 22,
  xl: 25,
  xxl: 47,
};

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 's',
      validator: val => ['s', 'm', 'l', 'xl', 'xxl'].indexOf(val) > -1,
    },
    selected: {
      type: Boolean,
      default: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconName() {
      return this.selected ? `${this.name}-selected` : this.name;
    },
    iconSize() {
      return `size-${sizeMap[this.size] || ''}`;
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
